export const cx = (...classes: any): string => {
  return classes.filter(Boolean).join(" ");
};

export const getQueryParam = (url: string, name: string) => {
  const fullUrl = new URL(url);
  return fullUrl.searchParams.get(name);
};

export const getFirstDayOfThisMonth = () => {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), 1);
};

export const getFirstDayOfThisYear = () => {
  const now = new Date();
  return new Date(now.getFullYear(), 0, 1);
};

export const getDateXDaysAgo = (numOfDays: number, date = new Date()) => {
  const daysAgo = new Date(date.getTime());
  daysAgo.setDate(date.getDate() - numOfDays);

  daysAgo.setHours(0, 0, 0, 0);

  return daysAgo;
};

export const getLastDayOfLastMonth = () => {
  const today = new Date();
  return new Date(today.getFullYear(), today.getMonth(), 0);
};

export const getFirstDayOfLastMonth = () => {
  const today = new Date();
  return new Date(today.getFullYear(), today.getMonth() - 1, 1);
};

export const fmtUSD = new Intl.NumberFormat(undefined, {
  style: "currency",
  currency: "USD",
});

export const fillArrayDateRange = (start: Date, end: Date): Date[] => {
  for (
    var arr = [], dt = new Date(start);
    dt <= new Date(end);
    dt.setDate(dt.getDate() + 1)
  ) {
    arr.push(new Date(dt));
  }
  return arr;
};

export const trackEvent = async (name: string, url: string, clientIp?: string | null) => {
  if (process.env.NODE_ENV === "development") return;
  await fetch("https://plausible.io/api/event", {
    body: JSON.stringify({
      name,
      url,
      domain: "app.buildjet.com,all-sites.buildjet.com",
    }),
    headers: {
      "Content-Type": "application/json",
      ...(clientIp && { "X-Forwarded-For": clientIp }),
    },
    method: "POST",
  });
};

type StatusReport = {
  id: string;
  type: string;
  attributes: {
    title: string;
    report_type: string;
    starts_at: string;
    ends_at: string | null;
    status_page_id: number;
    affected_resources: {
      status_page_resource_id: string;
      status: "resolved" | string;
    }[];
    aggregate_state: "degraded" | string;
  };
  relationships: {
    status_updates: {
      data: {
        id: string;
        type: string;
      }[];
    };
  };
};

export const uptime = {
  // We inherit the
  uptimeFetch: async (url: string, API_KEY: string) => {
    const headers = new Headers();
    headers.set("Authorization", `Bearer ${API_KEY}`);
    const res = await fetch(url, { headers });
    return await res.json();
  },
  isBuildJetOperational: async (API_KEY: string) => {
    const { data } = (await uptime.uptimeFetch(
      `https://betteruptime.com/api/v2/status-pages/130604`,
      API_KEY,
    )) as {
      data: {
        id: string;
        type: string;
        attributes: {
          company_name: string;
          company_url: string;
          contact_url: null;
          logo_url: string;
          timezone: string;
          subdomain: string;
          custom_domain: string;
          custom_css: null;
          google_analytics_id: null;
          min_incident_length: number;
          announcement: null;
          announcement_embed_enabled: true;
          announcement_embed_css: string;
          announcement_embed_link: string;
          subscribable: false;
          hide_from_search_engines: false;
          password_enabled: false;
          history: number;
          aggregate_state: string;
          created_at: string;
          updated_at: string;
        };
      };
    };
    return data?.attributes?.aggregate_state === "operational";
  },
  getLatestStatusReport: async (API_KEY: string) => {
    const { data } = (await uptime.uptimeFetch(
      `https://betteruptime.com/api/v2/status-pages/130604/status-reports`,
      API_KEY,
    )) as {
      data: StatusReport[];
    };

    const affectedResources = data.at(-1)?.attributes?.affected_resources;
    const isDashboardAffected =
      affectedResources?.some(
        x => x.status_page_resource_id === "106108" && x.status !== "resolved",
      ) ?? false;

    const isGitHubActionsAffected =
      affectedResources?.some(
        x => x.status_page_resource_id === "106109" && x.status !== "resolved",
      ) ?? false;

    console.log({ isDashboardAffected, isGitHubActionsAffected });
    return {
      isDashboardAffected: isDashboardAffected,
      isGitHubActionsAffected: isGitHubActionsAffected,
    };
  },
};

export const fmtDate = (date: Date) => {
  return new Intl.DateTimeFormat("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  }).format(date);
};

export const formatters = {
  date: (date: Date) =>
    new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    }).format(date),

  bytes: (bytes: number, decimals = 2) => {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  },

  hertz: (hz: number, decimals = 2) => {
    if (!+hz) return "0 Hz";

    const k = 1000; // The scaling factor for Hertz is 1000
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Hz", "kHz", "MHz", "GHz", "THz", "PHz", "EHz", "ZHz", "YHz"];

    const i = Math.floor(Math.log(hz) / Math.log(k));

    return `${parseFloat((hz / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  },

  timeAgo: (date: Date, options?: Intl.RelativeTimeFormatOptions) => {
    const relativeTimeFormat = new Intl.RelativeTimeFormat("en-US", {
      numeric: "auto",
      ...(options || {}),
    });

    const DIVISIONS: {
      amount: number;
      name: Intl.RelativeTimeFormatUnit;
    }[] = [
      { amount: 60, name: "seconds" },
      { amount: 60, name: "minutes" },
      { amount: 24, name: "hours" },
      { amount: 7, name: "days" },
      { amount: 4.34524, name: "weeks" },
      { amount: 12, name: "months" },
      { amount: Number.POSITIVE_INFINITY, name: "years" },
    ];

    let duration = (date.valueOf() - new Date().valueOf()) / 1000;
    for (let i = 0; i < DIVISIONS.length; i++) {
      const division = DIVISIONS[i];
      if (Math.abs(duration) < division.amount) {
        return relativeTimeFormat.format(Math.round(duration), division.name);
      }
      duration /= division.amount;
    }
  },

  price: (
    price: number | bigint | string,
    options?: Intl.NumberFormatOptions,
  ): string => {
    let opts: Intl.NumberFormatOptions = {
      style: "currency",
      currency: "USD",
      localeMatcher: "lookup",
      ...(options || {}),
    };

    // Convert the price to a number for comparison
    const numericPrice = typeof price === "string" ? parseFloat(price) : Number(price);

    // Check if the price is less than 1 and not zero, then adjust minimumFractionDigits
    if (numericPrice > 0 && numericPrice < 1) {
      opts.minimumFractionDigits = Math.max(2, -Math.floor(Math.log10(numericPrice)));
    } else {
      opts.minimumFractionDigits = 0;
    }

    return new Intl.NumberFormat("en-US", opts).format(numericPrice);
  },
};

export const getBufferFromUrl = async (url: string) => {
  const res = await fetch(url);
  const pdfBuffer = await res.arrayBuffer();
  return Buffer.from(pdfBuffer);
};

